import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Users, Projects,screenshot, appService } from '../admin-data-model/adminData.model'
import { map, take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public apiURL = '';
  constructor(
    private http: HttpClient) {
      this.apiURL = environment.backendBaseURL;
  }

  private getStore(key) {
    return localStorage.getItem(key);
  }

  private saveStore(key, value) {
    if (value != null) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  getSavedUsers(): [any] {
    return localStorage.getItem('getAllUsers') ? JSON.parse(localStorage.getItem('getAllUsers')) : [];
  }

  getSavedProjects(): [any] {
    return localStorage.getItem('getAllProjects') ? JSON.parse(localStorage.getItem('getAllProjects')) : [];
  }

  getSavedProducts(): [any] {
    return localStorage.getItem('getAllProducts') ? JSON.parse(localStorage.getItem('getAllProducts')) : [];
  }

  getSavedService() {
    return localStorage.getItem('appServices') ? JSON.parse(localStorage.getItem('appServices')) : [];
  }

  getProductById(id) {
    return this.getSavedProducts().find(product => product.id == id);
  }
  
  getProjectById(id) {
    return this.getSavedProjects().find(project => project.id == id);
  } 

  getAllUsers() {
    return this.http.get<Users>(this.apiURL + '/usersApi/users')
    .pipe(map(response =>  {
      localStorage.setItem('getAllUsers', JSON.stringify(response.users));
      return response;
    }))
    .pipe(take(1)).toPromise()
    .then((users) => {
      return users;
    })   
  }

  getAllProjects(){
    return this.http.get<Projects>(this.apiURL + '/projectApi/admin/projects')
    .pipe(map(response =>  {
      localStorage.setItem('getAllProjects', JSON.stringify(response.projects));
      return response;
    }))
    .pipe(take(1)).toPromise()
    .then((projects) => {
      return projects;
    })   
  }

  getAllProducts(){
    return this.http.get(this.apiURL + '/productApi/products')
    .pipe(map(response =>  {
      localStorage.setItem('getAllProducts', JSON.stringify(response));
      return response;
    }))
    .pipe(take(1)).toPromise()
    .then((products) => {
      return products;
    })   
  }

  getAppServices() {
      return this.http.get(this.apiURL + '/serviceApi/services/app')
      .pipe(map(response =>  {
        localStorage.setItem('appServices', JSON.stringify(response));
        return response;
      }))
      .pipe(take(1)).toPromise()
      .then((services) => {
        return services;
      })   
  }
  
  getAppServiceById(id) {
    return this.http.get(this.apiURL + '/serviceApi/service/app?id=' + id)
  }

  updateAppService(body){
    return this.http.post<appService>(this.apiURL + '/serviceApi/service/app', body)
  }

  updateProduct(body){
    return this.http.patch(this.apiURL + '/productApi/product', body)
  }

  addScreenshotToProduct(body){
    return this.http.post<screenshot>(this.apiURL + '/productApi/product/screenshot', body)
  }

  removeScreenshotToProduct(body){
    return this.http.delete<screenshot>(this.apiURL + `/productApi/product/screenshot?id=${body.id}&size=${body.size}&platform=${body.platform}&url=$${body.url}`)
  }

  addStepInAppService(body){
    return this.http.post<appService>(this.apiURL + '/serviceApi/service/app/step', body)
  }

  updateServiceStep(body) {
    return this.http.patch<appService>(this.apiURL + '/serviceApi/service/app/step', body)
  }

  removeStepInAppService(serviceId, stepId){
    return this.http.delete<appService>(this.apiURL + `/serviceApi/service/app/${serviceId}/step?id=${stepId}`)
  }
}